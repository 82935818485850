/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const ruLocalization = {
    translation: {
        layouts: {
            header: {
                t_language: 'RU',
                t_menu_project: 'Проекты',
                t_menu_blog: 'Блог',
            },
            footer: {
                t_text: '2024 Виталий Зарубин (keygenqt)',
            },
        },
        pages: {
            home: {
                t_block_1_name: 'Виталий Зарубин',
                t_block_1_title: 'Добро пожаловать!',
                t_block_1_subtitle: '...на сайт ведущего инженер-программиста компании Открытая Мобильная Платформа, эксперта Mobile Broadcast, немного блогера, и просто хорошего человека по данным полученным от моей жены и дочери ;)'
            },
        },
    },
};
