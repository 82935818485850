/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const enLocalization = {
    translation: {
        layouts: {
            header: {
                t_language: 'EN',
                t_menu_project: 'Projects',
                t_menu_blog: 'Blog',
            },
            footer: {
                t_text: '2024 Vitaliy Zarubin (keygenqt)'
            },
        },
        pages: {
            home: {
                t_block_1_name: 'Vitaly Zarubin',
                t_block_1_title: 'Welcome!',
                t_block_1_subtitle: '...to the site of the leading software engineer of the Open Mobile Platform company, an expert of Mobile Broadcast, a bit of a blogger, and just a good person according to the data received from my wife and daughter ;)'
            }
        },
    },
};
