/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { keyframes } from '@mui/system';
import {
    useTheme,
    useMediaQuery,
    Box,
    Stack,
    Typography,
} from '@mui/material';
import {
    useCacheStorage,
    DataImages,
    CacheKeys,
} from '../../base'


export function HomePage(props) {
    const theme = useTheme()
    const isLG = useMediaQuery(theme.breakpoints.down('lg'))
    const isMD = useMediaQuery(theme.breakpoints.down('md'))
    const isSM = useMediaQuery(theme.breakpoints.down('sm'))
    const {t, i18n} = useTranslation()

    const darkMode = useCacheStorage(CacheKeys.darkMode, false, false)

    const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }`;

    return (
        <Stack sx={{
            position: 'relative',
            background: 'linear-gradient(0.10turn, #2c306f, #86beb7)',
            justifyContent: 'center',
            alignItems: 'center',
            p: isMD ? 4 : 8,
            overflow: 'hidden',
        }}>
            <Stack
                direction={'row'}
                sx={{
                    width: 1,
                    maxWidth: 1078,
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Box sx={{width: 1}}>
                    <Stack
                        sx={{
                            p: isMD ? 4 : 8,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            boxSizing: 'border-box',
                            width: isLG ? 1 : 840,
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'background.default',
                                opacity: 0.9
                            }
                        }}
                    >
                        <Stack
                            spacing={3}
                            sx={{
                                p: isMD ? 4 : 8,
                                width: isLG ? 1 : 'calc(150% - 64px)',
                                backgroundColor: 'primary.main',
                                position: 'relative',
                                left: isLG ? 0 : 'calc(25% - 32px)',
                                boxSizing: 'border-box',
                                color: '#fff',
                                flex: 1,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="subtitle1">
                                {t('pages.home.t_block_1_name')}
                            </Typography>
                            <Typography
                                variant="h1"
                                sx={isSM ? {
                                    fontSize: '24px', fontWeight: 'bold'
                                } : isMD ? {
                                    fontSize: '60px'
                                } : isLG ? {
                                    fontSize: '75px'
                                } : {
                                    fontSize: '100px'
                                }}
                            >
                                {t('pages.home.t_block_1_title')}
                            </Typography>
                            <Typography variant="h6">
                                {t('pages.home.t_block_1_subtitle')}
                            </Typography>

                            <Box
                                sx={{
                                    height: 40,
                                    width: 40,
                                    backgroundSize: 'contain',
                                    backgroundPositionY: 'bottom',
                                    backgroundPositionX: 'left',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${DataImages.bgCubeYellow})`,
                                    position: 'absolute',
                                    left: '-20px',
                                    top: '-40px',
                                    animation: `${spin} 10s infinite linear`,
                                    zIndex: 99,
                                }}
                            />
                            <Box
                                sx={{
                                    height: 40,
                                    width: 40,
                                    backgroundSize: 'contain',
                                    backgroundPositionY: 'bottom',
                                    backgroundPositionX: 'left',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${DataImages.bgCubeYellow})`,
                                    position: 'absolute',
                                    left: '-20px',
                                    top: '-40px',
                                    animation: `${spin} 10s infinite reverse linear`,
                                    zIndex: 99
                                }}
                            />

                        </Stack>
                    </Stack>
                </Box>
                {!isLG && (<Box sx={{minWidth: 1}}/>)}
            </Stack>
            <Box
                sx={{
                    height: 400,
                    width: 1,
                    backgroundSize: 'contain',
                    backgroundPositionY: 'top',
                    backgroundPositionX: 'right',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${darkMode ? DataImages.darkHomeBgTop : DataImages.lightHomeBgTop})`,
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box
                sx={{
                    height: 400,
                    width: 1,
                    backgroundSize: 'contain',
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'left',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${darkMode ? DataImages.darkHomeBgBottom : DataImages.lightHomeBgBottom})`,
                    position: 'absolute',
                    bottom: 0,
                }}
            />
            <Box
                sx={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                    backgroundColor: '#ffd048',
                    position: 'absolute',
                    left: '10%',
                    top: '10%',
                }}
            />
            <Box
                sx={{
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    backgroundColor: '#ffd048',
                    position: 'absolute',
                    left: 'calc(10% - 60px)',
                    top: 'calc(10% + 90px)',
                    opacity: 0.8
                }}
            />
            <Box
                sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 100,
                    backgroundColor: '#ffd048',
                    position: 'absolute',
                    bottom: -70,
                    left: '84%'
                }}
            />
            <Box
                sx={{
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    backgroundColor: '#ffd048',
                    position: 'absolute',
                    right: 'calc(4% + 60px)',
                    bottom: 'calc(20% + 90px)',
                    opacity: 0.9
                }}
            />
            <Box
                sx={{
                    height: 70,
                    width: 70,
                    borderRadius: 70,
                    backgroundColor: '#ffd048',
                    position: 'absolute',
                    right: '20%',
                    bottom: '40%',
                }}
            />
            <Box
                sx={{
                    height: 20,
                    width: 20,
                    backgroundSize: 'contain',
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'left',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${DataImages.bgCross})`,
                    position: 'absolute',
                    right: '10%',
                    top: '10%',
                    animation: `${spin} 4s infinite ease`
                }}
            />
            <Box
                sx={{
                    height: 30,
                    width: 30,
                    backgroundSize: 'contain',
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'left',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${DataImages.bgCube})`,
                    position: 'absolute',
                    left: '100px',
                    bottom: '10%',
                    animation: `${spin} 3s infinite reverse ease-out`
                }}
            />
        </Stack>
    );
}


HomePage.propTypes = {};
