/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { createBrowserRouter } from "react-router-dom";
import * as Pages from './../../pages'
import * as Layouts from './../../layouts'


export const router = createBrowserRouter([
    createRoute({
        path: '/',
        element: (
            <Layouts.BaseLayout>
                <Pages.HomePage/>
            </Layouts.BaseLayout>
        ),
    }),
    createRoute({
        path: '/projects',
        element: (
            <Layouts.BaseLayout>
                <Pages.ProjectsPage/>
            </Layouts.BaseLayout>
        ),
    }),
    createRoute({
        path: '/blog',
        element: (
            <Layouts.BaseLayout>
                <Pages.BlogPage/>
            </Layouts.BaseLayout>
        ),
    })
]);

function createRoute(props) {
    return {
        ...props,
        errorElement: (
            <Layouts.BaseLayout justifyContent={'center'}>
                <Pages.ErrorPage />
            </Layouts.BaseLayout>
        ),
    }
}
