/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useCacheStorage,
    CacheStorage,
    CacheKeys,
} from '../../base';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Stack,
    Divider,
    Button,
} from '@mui/material';
import {
    Home,
    Brightness5,
    Brightness7,
} from '@mui/icons-material';


export function Header(props) {
    const darkMode = useCacheStorage(CacheKeys.darkMode, false, false)
    const {t, i18n} = useTranslation()

    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{backgroundColor: 'primary.main'}}
        >
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                >
                    <Home />
                </IconButton>
                <Typography
                    noWrap
                    variant={'h5'}
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'block'
                        },
                        '& span:first-of-type': {
                            color: 'primary.light',
                        },
                        '& span:last-of-type': {
                            color: 'secondary.light',
                        }
                    }}
                >
                    <span>M</span>y <span>W</span>ebsite
                </Typography>
                <Box sx={{ flexGrow: 1 }}/>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{marginRight: -1}}
                >
                    <Button
                        color={'inherit'}
                        onClick={() => {

                        }}
                    >
                        {t('layouts.header.t_menu_project')}
                    </Button>
                    <Button
                        color={'inherit'}
                        onClick={() => {

                        }}
                    >
                        {t('layouts.header.t_menu_blog')}
                    </Button>
                    <Divider
                        orientation={'vertical'}
                        variant={'middle'}
                        flexItem
                        sx={{
                            borderColor: '#ffffff21',
                            m: '6px !important'
                        }}
                    />
                    <IconButton
                        edge={'end'}
                        color={'inherit'}
                        onClick={() => {
                            CacheStorage.set(CacheKeys.darkMode, !darkMode, false)
                        }}
                    >
                        {darkMode ? ( <Brightness7/> ) : ( <Brightness5/> )}
                    </IconButton>
                    <IconButton
                        edge={'end'}
                        color={'inherit'}
                        onClick={() => {
                            i18n.changeLanguage(i18n.language === 'ru-RU' ? 'en-US' : 'ru-RU')
                        }}
                    >
                        <Stack sx={{
                            height: 24,
                            width: 24,
                            background: '#fff',
                            borderRadius: 24,
                            color: 'primary.main',
                            fontSize: 10,
                            fontWeight: 'bold',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box>{t('layouts.header.t_language')}</Box>
                        </Stack>
                    </IconButton>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {};
