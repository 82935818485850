/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Header } from './elements/Header';
import { Footer } from './elements/Footer';

export function BaseLayout(props) {
    return (
        <Stack sx={{
            backgroundColor: 'background.default',
            flex: 1,
            minHeight: 1,
        }}>
            <Header/>
            <Stack sx={{
                flex: 1,
                justifyContent: props.justifyContent,
            }}>
                {props.children}
            </Stack>
            <Footer/>
        </Stack>
    )
}

BaseLayout.propTypes = {
    children: PropTypes.element.isRequired,
    justifyContent: PropTypes.string,
};
