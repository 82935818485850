/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import logo from '../../assets/images/logo.svg';
import light_home_bg_bottom from '../../assets/images/light_home_bg_bottom.png';
import light_home_bg_top from '../../assets/images/light_home_bg_top.png';
import dark_home_bg_bottom from '../../assets/images/dark_home_bg_bottom.png';
import dark_home_bg_top from '../../assets/images/dark_home_bg_top.png';
import bg_cross from '../../assets/images/bg_cross.png';
import bg_cube from '../../assets/images/bg_cube.png';
import bg_cube_yellow from '../../assets/images/bg_cube_yellow.png';


export const DataImages = {
    logo: logo,
    lightHomeBgBottom: light_home_bg_bottom,
    lightHomeBgTop: light_home_bg_top,
    darkHomeBgBottom: dark_home_bg_bottom,
    darkHomeBgTop: dark_home_bg_top,
    bgCross: bg_cross,
    bgCube: bg_cube,
    bgCubeYellow: bg_cube_yellow,
};
