/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Colors MUI
 * @link https://mui.com/material-ui/customization/palette/
 */
export const palette = {
    mode: 'dark',
    primary: {
        main: '#23265a',
        light: '#838aff',
        dark: '#141526',
        contrastText: '#fff',
    },
    secondary: {
        main: '#a9dcd7',
        light: '#a9dcd7',
        dark: '#a9dcd7',
        contrastText: '#ffffff',
    },
}
